import MsgTrans from '@components/common/i18n/message-trans'
import { Stars } from '@components/common/icons/custom-icons'
import BookTrialClassContainer from '@containers/book-trial-class/book-trial-class-container'
import {
  COUNTRIES,
  GEO_CONTENT,
  HEADER_TEXT_GRADIENT,
  LANGUAGES,
  LanguageType,
} from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { RatingType, StatType } from '@lib/types/geo-lp'
import dynamic from 'next/dynamic'

const DynamicCodingalLogo = dynamic(() =>
  import('@components/landing-page/geo-landing-page/first-fold/codingal-logo')
)
const DynamicCodingalPartnerLogo = dynamic(() =>
  import(
    '@components/landing-page/geo-landing-page/first-fold/codingal-parter-logo'
  )
)

const { ENGLISH } = LANGUAGES

type StatsProps = {
  className?: string
  stats: StatType<string | LinguiJSMessageDescriptor>[]
  ratings: RatingType<string | LinguiJSMessageDescriptor>[]
}

const StatsSection = ({ className, stats, ratings }: StatsProps) => (
  <div className={`flex flex-col space-y-8 ${className}`}>
    <div className="flex justify-center px-2 space-x-6 lg:justify-start">
      {stats.map(({ statNumber, statSubHeading }) => (
        <div
          className="flex flex-col"
          key={
            typeof statSubHeading === 'string'
              ? statSubHeading
              : statSubHeading?.id
          }
        >
          <span className="inline-block text-2xl font-700 text-orange">
            <MsgTrans msg={statNumber} />
          </span>
          <p className="relative inline-block text-grey">
            <MsgTrans msg={statSubHeading} />
            <br />
            <span className="absolute bottom-0 inline-block w-6 border-b-2 border-orange" />
          </p>
        </div>
      ))}
    </div>

    <div className="flex justify-center space-x-2 lg:justify-start">
      {ratings.map(({ ratingNumber, ratingSubHeading }, index) => (
        <div
          key={index}
          className="flex flex-col w-32 px-4 py-2 space-y-1 border-2 rounded-md border-grey-400"
        >
          <span className="text-2xl font-700 text-orange">{ratingNumber}</span>
          <span className="flex-1 text-sm leading-tight text-grey">
            <MsgTrans msg={ratingSubHeading} />
          </span>
          <Stars className="w-16" />
        </div>
      ))}
    </div>
  </div>
)

type Props = {
  countryCode?: string
  shouldHaveFormShadow?: boolean
  language?: LanguageType
  partnerLogo?: string
  logoImageProperties?: {
    width?: number
    height?: number
    alt?: string
  }
  customHeading?: string
  customSubHeading?: string
  trialCourseUrl?: string
}

const FirstFold = ({
  countryCode,
  shouldHaveFormShadow,
  language = ENGLISH,
  partnerLogo,
  logoImageProperties = {
    height: 40,
    width: 115,
    alt: 'Partner logo',
  },
  customHeading,
  customSubHeading,
  trialCourseUrl,
}: Props) => {
  const {
    heroFold: { formatHeading, subHeading, stats, ratings, form },
  } = GEO_CONTENT[language]

  return (
    <div className="relative lg:py-10">
      {!partnerLogo && <DynamicCodingalLogo />}

      {partnerLogo && (
        <DynamicCodingalPartnerLogo
          partnerLogo={partnerLogo}
          logoImageProperties={logoImageProperties}
        />
      )}

      <div className="z-10 relative flex flex-col items-center justify-center space-y-10 px-5 lg:px-24 lg:flex-row lg:space-x-24">
        <div className="flex flex-col w-full px-2 space-y-8 md:w-128 lg:px-0">
          <div className="flex flex-col items-center space-y-5 lg:items-start">
            <h1
              className="pt-3 px-10 mt-4 text-3xl leading-none text-center font-700 lg:px-0 md:text-5xl lg:text-left md:mt-10 lg:mt-12"
              style={HEADER_TEXT_GRADIENT}
            >
              {customHeading
                ? customHeading
                : formatHeading(COUNTRIES[countryCode])}
            </h1>
            <span
              className="inline-block px-4 py-1 text-center rounded-md lg:text-left text-grey"
              style={{ backgroundColor: '#E0E3FD' }}
            >
              {customSubHeading || subHeading}
            </span>
          </div>
          <StatsSection
            className="hidden lg:block"
            stats={stats}
            ratings={ratings}
          />
        </div>
        <div className="z-10 mt-4 lg:mt-0">
          <BookTrialClassContainer
            countryCode={countryCode}
            shouldHaveFormShadow={shouldHaveFormShadow}
            phoneFieldLabel={form.phoneFieldLabel}
            cta={form.ctaText}
            gradeFieldPlaceholder={form.gradeFieldPlaceholder}
            hasLaptopFieldLabels={form.hasLaptopField}
            termsAndConditions={form.termsAndConditions}
            trialCourseUrl={trialCourseUrl}
          />
        </div>
        <StatsSection
          className="block lg:hidden"
          stats={stats}
          ratings={ratings}
        />
      </div>
    </div>
  )
}
export default FirstFold
