import { FastlyImage } from '@components/common/image'
import { Quotation, Stars } from '@components/common/icons/custom-icons'
import MsgTrans from '@components/common/i18n/message-trans'
import { t } from '@lingui/macro'

type Props = {
  testimonial: {
    name: any
    subheading: string
    profile?: string
    content: string
    imageName: string
  }
  testimonialImgSizes: Record<string, { height: number; width: number }>
  localeCountryCode: string
  imagePath: string
}

const TestimonialCard = ({
  testimonial,
  testimonialImgSizes,
  localeCountryCode,
  imagePath,
}: Props) => {
  return (
    <div
      className="relative py-4 px-4 rounded-md text-grey"
      style={{ border: '1px solid #DFE3EB' }}
    >
      <div>
        <Quotation className="h-10" />
      </div>
      <div className="flex items-center space-x-5">
        <div>
          <FastlyImage
            src={`${imagePath}${testimonial.imageName}.png`}
            alt={
              typeof testimonial.name === 'string'
                ? testimonial.name
                : t({ id: testimonial.name?.id })
            }
            width={testimonialImgSizes[localeCountryCode || 'default'].width}
            height={testimonialImgSizes[localeCountryCode || 'default'].height}
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xl capitalize font-700">
            <MsgTrans msg={testimonial.name} />
          </span>
          {testimonial?.profile && (
            <span className="mb-2 text-orange">
              <MsgTrans msg={testimonial.profile} />
            </span>
          )}
          <span
            className="inline-block px-2 rounded-md text-grey"
            style={{ backgroundColor: '#E0E3FD' }}
          >
            <MsgTrans msg={testimonial.subheading} />
          </span>
          <Stars />
        </div>
      </div>
      <span className="inline-block w-full h-1 border-b border-grey-300"></span>

      <p className="pt-6 text-lg leading-tight font-600">
        <MsgTrans msg={testimonial.content} />
      </p>
    </div>
  )
}
export default TestimonialCard
