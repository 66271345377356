import { ABSOLUTE_ROUTES } from '@common/enums'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import TestimonialCard from '@components/landing-page/geo-landing-page/testimonial-card'
import {
  CODING_PROJECT_TESTIMONIAL,
  ENGLISH_TESTIMONIAL_CONTENTS,
} from '@lib/constants/geo-contents/english'
import {
  COUNTRY_CODES,
  GEO_CONTENT,
  LANGUAGES,
  LanguageType,
} from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'

const { PK, US, JP } = COUNTRY_CODES
const { REVIEWS } = ABSOLUTE_ROUTES
const { ENGLISH } = LANGUAGES

export type TestimonialType = {
  name: LinguiJSMessageDescriptor
  imageName: string
  subheading: LinguiJSMessageDescriptor
  content: LinguiJSMessageDescriptor
}[]
type Props = {
  shouldCtaScrollToTop?: boolean
  language?: LanguageType
  isRightToLeftPage?: boolean
  localeCountryCode?: string
  isCodingProject?: boolean
  showReviewLink?: boolean
  cta?: string
  title?: JSX.Element
  customTestimonials?: TestimonialType
}

/** i18n support added */
const TestimonialsFold = ({
  shouldCtaScrollToTop = true,
  language = ENGLISH,
  isRightToLeftPage,
  localeCountryCode,
  isCodingProject,
  showReviewLink = true,
  cta,
  title,
  customTestimonials,
}: Props) => {
  const { testimonialsFold, bookTrialClassCTA } = GEO_CONTENT[language]

  const testimonials = customTestimonials
    ? customTestimonials
    : isCodingProject
    ? CODING_PROJECT_TESTIMONIAL
    : localeCountryCode
    ? ENGLISH_TESTIMONIAL_CONTENTS[localeCountryCode]
    : testimonialsFold?.contents

  const IMAGE_PATH = `/images/geo-landing-page/testimonials/${
    localeCountryCode ? `${localeCountryCode}/` : ''
  }`

  const testimonialImgSizes = {
    default: {
      height: 144,
      width: 127,
    },
    PK: {
      height: 144,
      width: 110,
    },
    JP: {
      height: 138,
      width: 120,
    },
    US: {
      height: 127,
      width: 112,
    },
  }

  const shouldShowReviewLink =
    showReviewLink && ![PK, US, JP].includes(localeCountryCode)

  return (
    <>
      <div
        dir={isRightToLeftPage ? 'rtl' : null}
        className="flex flex-col items-center px-5 md:px-3 mt-16 space-y-10 lg:px-0"
      >
        <h2 className="text-2xl text-center text-grey font-600 lg:text-4xl">
          {title ? (
            title
          ) : (
            <>
              {isRightToLeftPage && (
                <>
                  <span className="underlined-text">
                    <MsgTrans msg={testimonialsFold.heading.underlinedText} />
                  </span>{' '}
                  <MsgTrans msg={testimonialsFold.heading.normalText} />
                </>
              )}

              {!isRightToLeftPage && testimonialsFold.heading.wholeHeadingInJsx}
            </>
          )}
        </h2>
        <div className="grid grid-cols-1 gap-6 mx-auto lg:grid-cols-2 lg:max-w-6xl lg:px-32">
          {testimonials.map((testimonial) => (
            <TestimonialCard
              key={
                typeof testimonial.name === 'string'
                  ? testimonial.name
                  : testimonial.name?.id
              }
              testimonial={testimonial}
              imagePath={IMAGE_PATH}
              testimonialImgSizes={testimonialImgSizes}
              localeCountryCode={localeCountryCode}
            />
          ))}
        </div>

        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full mx-auto space-y-2 lg:flex-row lg:w-auto lg:space-y-0 lg:space-x-4">
            {shouldShowReviewLink && (
              <a
                href={REVIEWS}
                target="_blank"
                rel="noreferrer"
                className="text-center inline-block align-middle border rounded focus:outline-none font-700 px-10 py-2 h-11 text-orange hover:bg-orange-200 lg:mx-2"
              >
                <MsgTrans msg={testimonialsFold.reviewLinkText} />
              </a>
            )}
            <BookFreeClassBtn
              shouldScrollToTop={shouldCtaScrollToTop}
              ctaText={cta || bookTrialClassCTA}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default TestimonialsFold
