import Footer from '@components/book-trial-for-uae/footer'
import WhyCodingal from '@components/book-trial-for-uae/why-codingal'
import Benefits from '@components/landing-page/geo-landing-page/benefits'
import FirstFold from '@components/landing-page/geo-landing-page/first-fold'
import Partners from '@components/landing-page/geo-landing-page/partners'
import Testimonials from '@components/landing-page/geo-landing-page/testimonials'
import useStoreUTMParams from '@hooks/useStoreUTMParam'
import { geoLongLandingPageSeo } from '@lib/lp-seo'
import { NextSeo } from 'next-seo'

const LP_URL = `book-trial-class`

const BookTrialClass = () => {
  // TODO: Remove once getInitialProps is removed from _app.tsx
  useStoreUTMParams()

  return (
    <>
      <NextSeo {...geoLongLandingPageSeo(LP_URL)} />
      <FirstFold shouldHaveFormShadow={true} />
      <Partners />
      <Benefits />
      <Testimonials />
      <WhyCodingal />
      <Footer />
    </>
  )
}

// TODO: Remove once getInitialProps is removed from _app.tsx
export const getStaticProps = async () => {
  return {
    props: {},
  }
}

export default BookTrialClass
