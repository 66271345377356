import Accredited from '@components/landing-page/geo-landing-page/partners/accredited'
import Alumnis from '@components/landing-page/geo-landing-page/partners/alumins'
import {
  COUNTRY_CODES,
  GEO_CONTENT,
  LANGUAGES,
  LanguageType,
} from '@lib/constants/geo-lp'
import dynamic from 'next/dynamic'

const DynamicBackingCompanies = dynamic(() =>
  import('@components/landing-page/geo-landing-page/partners/backing-companies')
)
const DynamicPartnerCompanies = dynamic(() =>
  import('@components/landing-page/geo-landing-page/partners/partner-companies')
)

const { PK } = COUNTRY_CODES
const { ENGLISH } = LANGUAGES

type PartnersFoldProps = {
  language?: LanguageType
  localeCountryCode?: string
}

/** i18n support added */
const PartnersFold = ({
  language = ENGLISH,
  localeCountryCode,
}: PartnersFoldProps) => {
  const {
    partnersFold: {
      builtBySectionHeading,
      partnershipSectionHeading,
      stemSectionHeading,
      backedBySectionHeading,
    },
  } = GEO_CONTENT[language]

  const shouldShowPartnersSection = ![PK].includes(localeCountryCode)
  const shouldShowBackersSection = [PK].includes(localeCountryCode)

  return (
    <div className="container grid grid-cols-1 px-5 mx-auto mt-10 gap-y-5 lg:px-0 lg:gap-x-8 lg:max-w-5xl lg:grid-cols-3 relative">
      <Alumnis
        builtBySectionHeading={builtBySectionHeading}
        isOutSideIndia={Boolean(localeCountryCode)}
      />

      {shouldShowPartnersSection && (
        <DynamicPartnerCompanies
          partnershipSectionHeading={partnershipSectionHeading}
        />
      )}

      {shouldShowBackersSection && <DynamicBackingCompanies />}

      <Accredited
        stemSectionHeading={stemSectionHeading}
        backedBySectionHeading={backedBySectionHeading}
        isOutSideIndia={Boolean(localeCountryCode)}
      />
    </div>
  )
}

export default PartnersFold
