import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { PARTNERS_IMAGE_PATH } from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { t } from '@lingui/macro'
import dynamic from 'next/dynamic'

const DynamicAccreditedOutsideIndia = dynamic(() =>
  import(
    '@components/landing-page/geo-landing-page/partners/accredited-outside-india'
  )
)

type Props = {
  stemSectionHeading: string | LinguiJSMessageDescriptor
  backedBySectionHeading: string | LinguiJSMessageDescriptor
  isOutSideIndia?: boolean
}

const Accredited = ({
  stemSectionHeading,
  backedBySectionHeading,
  isOutSideIndia,
}: Props) => (
  <div
    className={`p-6 text-center bg-white rounded-lg shadow-lg h-${
      isOutSideIndia ? '48' : '64'
    }`}
  >
    <h3 className="mb-6 text-xl font-700 text-grey-800">
      <MsgTrans msg={stemSectionHeading} />
    </h3>
    <FastlyImage
      src={`${PARTNERS_IMAGE_PATH}/stem-logo.png`}
      className="mx-auto lg:mt-0 lg:mr-auto"
      alt={t`STEM accredited educational experience`}
      width={130}
      height={60}
    />

    {!isOutSideIndia && (
      <DynamicAccreditedOutsideIndia
        backedBySectionHeading={backedBySectionHeading}
      />
    )}
  </div>
)

export default Accredited
