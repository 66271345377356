import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { PARTNERS_IMAGE_PATH } from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { t } from '@lingui/macro'
import dynamic from 'next/dynamic'

const DynamicAlumnisOutsideIndia = dynamic(() =>
  import(
    '@components/landing-page/geo-landing-page/partners/alumnis-outside-india'
  )
)

type Props = {
  builtBySectionHeading: string | LinguiJSMessageDescriptor
  isOutSideIndia?: boolean
}

const Alumnis = ({ builtBySectionHeading, isOutSideIndia }: Props) => (
  <div
    className={`p-6 text-center bg-white rounded-lg shadow-lg h-${
      isOutSideIndia ? '48' : '64'
    }`}
  >
    <h3 className="mb-6 text-xl font-700 text-grey-800">
      <MsgTrans msg={builtBySectionHeading} />
    </h3>
    <div className="grid grid-cols-2 gap-y-5">
      <div className="flex flex-col items-center text-grey">
        <FastlyImage
          src={`${PARTNERS_IMAGE_PATH}/google.png`}
          alt={t`Google`}
          width={85}
          height={28}
        />
      </div>
      <div className="flex flex-col items-center text-grey">
        <FastlyImage
          width={95}
          height={28}
          src={`${PARTNERS_IMAGE_PATH}/amazon.png`}
          alt={t`Amazon`}
        />
      </div>
      {!isOutSideIndia && <DynamicAlumnisOutsideIndia />}
    </div>
  </div>
)

export default Alumnis
